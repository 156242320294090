<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <v-preview
      v-if="isPreview"
      :show="isPreview"
      :closePreivew="closePreview"
      :data="previewObject"
    />
    <alert-confirm
      :show="isDelete"
      title="Remove Confirmation"
      subTitle="Are you sure you want to remove this Department? This action will remove content in both Thai and English."
      :close="closeDeletePopup"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDelete"
      icon=""
    ></alert-confirm>
    <alert-confirm
      :show="isDeleteError"
      title="Department cannot be deleted."
      subTitle="This department cannot be deleted because there is Doctor under this branch."
      :close="closeDeleteErrorDialog"
      headline-color="black"
      :confirm="closeDeleteErrorDialog"
      icon=""
      buttonCancel="OK"
    ></alert-confirm>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <v-alert-success :show="isSuccess" :close="closeAlert"></v-alert-success>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            {{ textMode }} Department
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="isEditMode && hasPermDelete()"
            :show-preview="true"
            :show-save="!disabledEdit"
            :disabled-preview="disabledPreview"
            :on-remove="onRemove"
            :on-cancel="showCancelPopup"
            :on-preview="onPreview"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
          <h6 class="mb-0 card-title" t-data="info-title">
            Department Information
          </h6>
          <v-spacer></v-spacer>
          <p class="edit-field-label sub-body-bold mr-4" t-data="status-title">
            Status
          </p>
          <v-switch
            color="primary"
            v-model="departmentObject.status"
            :disabled="disabledEdit"
            :false-value="1"
            :true-value="0"
            class="mb-0 pb-0"
          ></v-switch>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-0">
          <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
            <v-col cols="3" t-data="info-input">
              <p class="edit-field-label sub-body-bold" t-data="info-label">
                Logo Image
              </p>
              <image-dropzone
                title="Logo Image"
                subtitle="Recommended size 40x40px."
                subtitle-font-class="small-bold"
                content-font-class="small"
                class="can-disable-selector"
                :thumbnail="departmentObject.image"
                :is-error="isImageError"
                :error-msg="imageErrorMessage"
                :disabled="disabledEdit"
                @update="updateImageDepartment"
                @remove="removeImageDepartment"
              ></image-dropzone>
            </v-col>
            <v-col cols="6" t-data="info-input">
              <single-selected-branch
                outlined
                required
                dense
                title="Branch <span class='danger500Color--text'>*</span>"
                :isDisabled="disabledEdit"
                :branch="branches"
                :selectedBranch="selectedBranch"
                :syncData="changedSelectedBranch"
                :isEditMode="isEditMode"
                v-if="!isLoading"
              ></single-selected-branch>
              <span class="edit-field-label sub-body-bold" t-data="info-label">
                Department Code
              </span>
              <v-text-field
                class="can-disable-selector"
                outlined
                dense
                :placeholder="disabledEdit ? '' : 'Input Department Code'"
                v-model="departmentObject.code"
                :disabled="disabledEdit"
                :error-messages="codeErrorMessage"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card class="border-card">
            <v-card-text>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    Thai
                  </h4>
                </v-col>
                <v-col cols="6">
                  <h4 class="mb-6 card-title" t-data="info-title">
                    English
                  </h4>
                </v-col>
              </v-row>

              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Department Name <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameThRules"
                    v-model="departmentObject.contents[0].title"
                    :placeholder="disabledEdit ? '' : 'Input Department Name'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Department Name <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :disabled="disabledEdit"
                    :rules="nameEnRules"
                    v-model="departmentObject.contents[1].title"
                    :placeholder="disabledEdit ? '' : 'Input Department Name'"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Description
                  </p>
                  <quill-editor
                    ref="quillTh"
                    v-model="departmentObject.contents[0].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
                <v-col cols="6" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Description
                  </p>

                  <quill-editor
                    ref="quillEn"
                    v-model="departmentObject.contents[1].description"
                    :options="editorOption"
                  ></quill-editor
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
      <created-detail
        v-if="isEditMode"
        :data-object="departmentObject"
      />
      <v-spacer></v-spacer>
      <edit-buttons
        class="mt-3"
        :show-remove="isEditMode && hasPermDelete()"
        :show-preview="true"
        :show-save="!disabledEdit"
        :disabled-preview="disabledPreview"
        :on-remove="onRemove"
        :on-cancel="showCancelPopup"
        :on-preview="onPreview"
        :on-save="onSave"
      />
    </v-row>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor, Quill} from 'vue-quill-editor'
import {mapState, mapActions} from 'vuex'

import PopupLoading from '@/components/PopupLoading'
import AlertSuccess from '@/components/AlertSuccess'
import moment from 'moment'
import ImageUploader from 'quill-image-uploader'
import MyLink from '@/constants/quill-link'
import Preview from '@/components/DepartmentPreview'
import {hasPermissionsByOrgs} from '@/helper/helper'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)
import {convertFileToBase64, convertSelectedData} from '../../helper/helper'
import AlertConfirm from '@/components/AlertConfirm'
import CreatedDetail from '@/components/CreatedDetail'
import EditButtons from '@/components/EditButtons'
import ImageDropzone from '@/components/ImageDropzone'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {actionEnum} from '@/constants/constants'
export default {
  components: {
    quillEditor,
    'v-alert-success': AlertSuccess,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    AlertConfirm,
    CreatedDetail,
    EditButtons,
    ImageDropzone,
    SingleSelectedBranch,
  },
  name: 'Edit',
  data() {
    return {
      datePicker: false,
      isEditMode: false,
      valid: true,
      isLoad: false,
      isDeleteError: false,
      isPreview: false,
      isCancel: false,
      title_th: '',
      title_en: '',
      image_th: '',
      image_en: '',
      isImageEngError: false,
      imageEngErrorMessage: '',
      isImageError: false,
      imageErrorMessage: '',
      description_th: '',
      description_en: '',
      categoryId: '',
      statusId: '',
      isError: false,
      isSuccess: false,
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      },
      nameThRules: [v => !!v || 'Department Name is required'],
      nameEnRules: [v => !!v || 'Department Name is required'],
      linkroute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'departments',
          disabled: false,
          href: '/department-list'
        },
        {
          text: this.textMode,
          disabled: true,
          href: '/'
        }
      ],
      date: moment(new Date())
        .add(1, 'days')
        .toISOString()
        .substr(0, 10)
    }
  },

  computed: {
    hospitalName() {
      return this.orgObject.filter(
        o => o.value === this.departmentObject.org_codename
      )[0].text
    },
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditDepartment', [
          this.departmentObject.org_codename
        ])
      )
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.departmentObject.org_codename !== null) {
        selectedBranch = this.departmentObject.org_codename
      }
      return selectedBranch
    },
    previewObject() {
      return {
        organization: {
          name: this.orgObject.filter(
            o => o.value === this.departmentObject.org_codename
          )[0].text,
          codename: this.departmentObject.org_codename
        },
        ...this.departmentObject
      }
    },
    textMode() {
      return this.isEditMode ? 'Edit' : 'Create'
    },
    breadcrumbs() {
      return [
        {text: 'Dashboard', links: true, to: '/'},
        {text: 'Departments', links: true, to: '/departments'},
        {text: this.textMode, links: false}
      ]
    },
    disabledPreview() {
      return (
        this.departmentObject.org_codename === '' ||
        this.departmentObject.contents[0].title === '' ||
        this.departmentObject.contents[1].title === ''
      )
    },
    ...mapState('department', [
      'codeErrorMessage',
      'departmentObject',
      'isDelete',
      'isLoading',
      'orgObject',
    ])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetList()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    this.setEditMode(this.isEditMode)

    if (this.isEditMode) {
      await this.setDepartmentId(this.$route.params.id)
      // scroll to top
      window.scrollTo(0, 0)
    } else {
      this.resetState()
    }

    const isViewOnly =
      this.isEditMode &&
      !hasPermissionsByOrgs('CanEditDepartment', [
        this.departmentObject.org_codename
      ])
    await this.setOrgInfo(isViewOnly)
  },
  mounted() {
    if (this.disabledEdit) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  watch: {
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    }
  },
  methods: {
    hasPermDelete() {
      return hasPermissionsByOrgs('CanDeleteDepartment', [
        this.departmentObject.org_codename
      ])
    },
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'department-list'
      })
    },
    async onRemove() {
      this.setDeleteId(this.$route.params.id)
      this.setDeletePopup(true)
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.departmentObject.org_codename = value[0]
      }
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    closeDeleteErrorDialog() {
      this.isDeleteError = false
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.$router.push({
          name: 'department-list',
          query: {isCreateSuccess: true, snackTitle: 'removed'}
        })
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    showFailSnackbar(action) {
      this.snackTitle = action
    },
    disableQuill() {
      this.$refs.quillTh.quill.enable(false)
      this.$refs.quillEn.quill.enable(false)
      this.$refs.quillTh.quill.root.dataset.placeholder = ''
      this.$refs.quillEn.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillTh.quill.enable(true)
      this.$refs.quillEn.quill.enable(true)
      this.$refs.quillTh.quill.root.dataset.placeholder = 'Insert text here ...'
      this.$refs.quillEn.quill.root.dataset.placeholder = 'Insert text here ...'
    },
    syncSelectedData(data) {
      this.departmentObject.orgs = convertSelectedData(
        data,
        this.orgObject,
        this.departmentObject.orgs
      )
    },
    onPreview() {
      this.isPreview = true
    },
    async onSave() {
      if (this.validate()) {
        if (this.isEditMode) {
          const status = await this.updateDepartment({
            id: this.$route.params.id,
            pkg: this.departmentObject
          })
          if (status) {
            this.$router.push({
              name: 'department-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } else {
            return (this.isError = true)
          }
        } else {
          const status = await this.create(this.departmentObject)
          if (status) {
            this.$refs.form.resetValidation()
            this.resetState()
            this.$router.push({
              name: 'department-list',
              query: {isCreateSuccess: true, snackTitle: 'created'}
            })
          } else {
            return (this.isError = true)
          }
        }
      }
    },
    removeImageDepartment() {
      this.departmentObject.image = ''
    },
    updateImageDepartment(file) {
      if (file && file.name) {
        if (!String(file.type).includes('image')) {
          this.isImageError = true
          this.imageErrorMessage = 'Only support image file!'
          return
        } else if (!file || file.size > 1040000) {
          this.isImageError = true
          this.imageErrorMessage =
            'Department image size should be less than 1 MB!'
          return
        } else {
          this.setImageDepartment(file)
          this.isImageError = false
          this.imageErrorMessage = ''
          return
        }
      } else {
        this.departmentObject.image = ''
      }
    },
    closePreview() {
      this.isPreview = false
    },
    closeAlert() {
      this.isError = false
      this.isSuccess = false
    },
    validate() {
      if (
        this.$refs.form.validate() &&
        !this.isImageError
      ) {
        return true
      } else {
        return false
      }
    },
    ...mapActions('department', [
      'create',
      'deletePkg',
      'resetList',
      'resetState',
      'setDeleteId',
      'setDeletePopup',
      'setDepartmentId',
      'setEditMode',
      'setImageDepartment',
      'setOrgInfo',
      'update',
      'updateDepartment',
      'upload',
    ])
  }
}
</script>

<style scoped></style>
